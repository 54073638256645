import UserService from "../api/UserService";

export const NutakuPaymentService = {

    start: async function (id: number) {


        let result = await UserService.nutakuPaymentCreate(id);
        
        let message = {
            event: 'payment',
            data: {
                order_id: result.data.id,
                id: id,
                price: 100,
                count: 1,
                description: '1000 gems',
                name: '1000 gems',
                imageUrl: 'https://example.com/gun.gif'
            }
        };


        window.parent.postMessage(message);
    }
}