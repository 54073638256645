import './EndGame.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";
import {sounds} from '../../../sounds/sound';
import {MusicService} from '../../../services/MusicService';

function EndGame() {
    const {phase, endGame} = useTypedSelector(state => state.game);
    const {cpuStory} = useTypedSelector(state => state.auth);
    const {setCpuStory, updateUser, setPhase, setCpuTable, setPlayerTable} = useActions();


    let rootClasses = 'endGameContainer';

    let silver = 0;
    let level = 0;
    let resultText = 'WIN!';

    if (phase === 'end_game' && endGame.winner !== null) {

        rootClasses += ' endGameContainerActive';
        silver = endGame.result.rewards.silver;
        level = endGame.result.rewards.new_level;

        MusicService.stopBattle();
        if (endGame.winner === 'cpu') {
            resultText = 'Defeat'
            MusicService.playBattleWin(sounds.battle_lose())
        } else {
            MusicService.playBattleWin(sounds.battle_win())
        }

        if (endGame.result.reason === 'empty_deck') {
            resultText += ' (deck is over)';
        }
    }

    let navigate = useNavigate();

    const clearState = function () {
        setPhase(null);
        setCpuTable({0: null, 1: null, 2: null, 3: null, 4: null});
        setPlayerTable({0: null, 1: null, 2: null, 3: null, 4: null});
    }

    return (
        <div className={rootClasses}>
            <div className={'endGameResult'}>
                <div className={'endGameCaption'}>{resultText}</div>
                <div className={'endGameReward'}>
                    <div className={'endGameRewardSilver'}>Gems: {silver}</div>
                    {level > 0 ? (
                        <div className={'engGameNewLevel'}>Reached a new level {level}</div>
                    ) : (
                        <div></div>
                    )
                    }

                </div>

                <div className={'endGameButton'}>
                    <Button size="large" type="primary" onClick={() => {
                     MusicService.stopBattleWin();
                        updateUser();
                        cpuStory.action = 'after';
                        setCpuStory(cpuStory);
                        clearState();
                        navigate('/home');
                        MusicService.playMenuMusic();
                    }}>Ok</Button>
                </div>
            </div>

        </div>
    );
}

export default EndGame;