import UserService from "../api/UserService";

export const Preloader  = {

    load: function (response: { data: any[]; }) {

         // @ts-ignore
        for (const image of response.data[0]) {
            const imageElement = new Image();
            imageElement.src = '/static/media' + image;
        };

        for (const image of response.data[1]) {
            const imageElement = new Image();
            imageElement.src = '/img' + image;
        };
        
    },


}