import {useTypedSelector} from "../../hooks/useTypedSelector";
import {Button} from "antd";
import Card from "./Card";
import {useActions} from "../../hooks/useActions";

function CardVeryBig(){
    const {cardBigSize} = useTypedSelector(state => state.auth);
    const {setCardBigSize} = useActions();
    let className = 'cardVeryBigContainerHide';
    if(cardBigSize !== false){
        className = 'cardVeryBigContainerVisible';
    }
    return (
        <div className={'cardVeryBigContainer ' + className } onClick={()=>{
            setCardBigSize(false);
        }}>

            {(cardBigSize !== false) ? (

             <Card card={cardBigSize} isVeryBigSize={true} />

            ) : (
                <div></div>
            )
            }

        </div>
    )
}
export default CardVeryBig