import React from 'react';

import './Card.css';
import ManaCost from "./ManaCost";
import UnitAD from "./UnitAD";
import {useActions} from "../../hooks/useActions";


function Card({card, targetClasses = '', battle = false, isBigSize = false, isVeryBigSize = false}) {

    const {setCardBigSize} = useActions();
    let cardRootClasses = 'cardContainer';

    let cardNameClasses = 'cardName';

    let abTextUnitFont = 'abilityTextUnitFont';

    if (card.name.length > 12) {
        cardNameClasses += ' cardNameBigName';
    }
    if (battle) {
        cardRootClasses += ' cardContainerSmall';
        cardNameClasses += ' cardNameBattle';
    }

    if (isBigSize) {
        cardRootClasses = ' cardContainerBig';
        cardNameClasses += ' cardNamePlayed';
    }

    if (isVeryBigSize) {
        cardRootClasses = ' cardContainerVeryBig';
        cardNameClasses = ' cardName cardNameVeryBig';
        abTextUnitFont = 'abilityTextUnitFontVeryBigSize';
    }


    return (
        <div className={cardRootClasses} onDoubleClick={() => {
            if (isVeryBigSize === true) {
                return;
            }
            setCardBigSize(card);
        }
        }
             onContextMenu={(event) => {
                 event.preventDefault()
                 if (isVeryBigSize === true) {
                     return;
                 }
                 setCardBigSize(card);
                 return false;
             }}
        >
            <div className={'rightBorderCorner rightBorderCornerBG-' + card.level}></div>
            <div className={'leftBorderCorner leftBorderCornerBG-' + card.level}></div>
            <div className={'cardNameBg cardNameBgImg-' + card.level}></div>
            <div className={targetClasses + ' cardTargetBorder'}></div>
            <div className={'cardBorder cardBorderBG-' + card.level}></div>
            <div className={cardNameClasses}>{card.name}</div>

            {(!battle) ?
                <ManaCost costs={card.cost} level={card.level} isVeryBigSize={isVeryBigSize}/>
                :
                <div></div>
            }


            {(card.ab_text) ?
                <div className='abilityText'>

                    {(card.type_card === 'unit' && card.ab_text.length > 11) ?
                        <div className={'abilityTextUnit ' + abTextUnitFont}>{card.ab_text}</div>
                        :
                        <div className='abText'>{card.ab_text}</div>
                    }
                </div>
                :
                <div></div>
            }

            {(card.type_card === 'unit') ?
                <UnitAD attack={card.attack} hp={card.hp} level={card.level} isBigSize={isBigSize}
                        isVeryBigSize={isVeryBigSize}/>
                :
                <div></div>
            }

            <img
                src={"/img/cards/mini/" + card.id + ".png"}
                className="cardImage"
                loading="eager"
            />

        </div>
    );
}

export default Card;