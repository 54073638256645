import React from 'react';

import './Tutorial.css';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Welcome10 from './steps/Welcome10';
import ClickBossOnMap20 from './steps/ClickBossOnMap20';
import BossWindow30 from './steps/BossWindow30';
import PlayFirstCard40 from './steps/PlayFirstCard40';
import PlaySecondCard50 from './steps/PlaySecondCard50';
import EndTurnBattle from './steps/EndTurnBattle';
import PutUnitOnTable52 from './steps/PutUnitOnTable52';
import SelectAttack60 from './steps/SelectAttack60';
import PlaySpellCard70 from './steps/PlaySpellCard70';
import SpellTarget75 from './steps/SpellTarget75';
import BattleFinish80 from './steps/BattleFinish80';
import ElfComics35 from './steps/ElfComics35';
import MenuResearch90 from './steps/MenuResearch90';
import ClickShop100 from './steps/ClickShop100';
import ClickDeck110 from './steps/ClickDeck110';
import ClickBuy120 from './steps/ClickBuy120';
import ClickAdd130 from './steps/ClickAdd130';
import ClickHome140 from './steps/ClickHome140';
import Final150 from './steps/Final150';
import ClickShopDone105 from "./steps/ClickShopDone105";


function TutorialComponent() {

    const {tutorial} = useTypedSelector(state => state.auth);


      let tutorialStep:any;
      tutorialStep = '';

      if(tutorial.isActive === true){
       if(tutorial.step === 'Welcome10'){
        tutorialStep = <Welcome10 />;
       }else if(tutorial.step === 'ClickBossOnMap20'){
        tutorialStep = <ClickBossOnMap20 />;
       }else if(tutorial.step === 'BossWindow30'){
        tutorialStep = <BossWindow30 />;
       }else if(tutorial.step === 'ElfComics35'){
        tutorialStep =<ElfComics35 />
       }else if(tutorial.step === 'PlayFirstCard40'){
        tutorialStep = <PlayFirstCard40 />;
       }else if(tutorial.step === 'PlaySecondCard50'){
        tutorialStep = <PlaySecondCard50 />;
       }else if(tutorial.step === 'SelectAttack60'){
        tutorialStep = <SelectAttack60 />;
       }else if(tutorial.step === 'EndTurnBattle45' || tutorial.step === 'EndTurnBattle55' || tutorial.step === 'EndTurnBattle65'){
        tutorialStep = <EndTurnBattle/>;
       }else if(tutorial.step === 'PutUnitOnTable52'){
        tutorialStep = <PutUnitOnTable52 />;
       }else if(tutorial.step === 'PlaySpellCard70'){
        tutorialStep = <PlaySpellCard70 />;
       }else if(tutorial.step === 'SpellTarget75'){
        tutorialStep = <SpellTarget75 />
       }else if(tutorial.step === 'BattleFinish80'){
        tutorialStep = <BattleFinish80 />
       }else if(tutorial.step === 'MenuResearch90'){
        tutorialStep = <MenuResearch90 />;
       }else if(tutorial.step === 'ClickShop100'){
        tutorialStep = <ClickShop100 />;
       }else if(tutorial.step === 'ClickShopDone105'){
        tutorialStep = <ClickShopDone105 />;
       }else if(tutorial.step === 'ClickDeck110'){
        tutorialStep = <ClickDeck110 />;
       }else if(tutorial.step === 'ClickBuy120'){
        tutorialStep = <ClickBuy120 />;
       }else if(tutorial.step === 'ClickAdd130'){
        tutorialStep = <ClickAdd130 />;
       }else if(tutorial.step === 'ClickHome140'){
        tutorialStep = <ClickHome140 />;
       }else if(tutorial.step === 'Final150'){
        tutorialStep = <Final150 />;
       }
   
    }

    return (
        <div>
        {tutorialStep}
        </div>
    );
}

export default TutorialComponent;