import Card from "../../card/Card";
import {useActions} from "../../../hooks/useActions";
import './Fireworks.css';
import {Button} from "antd";
import {useTypedSelector} from "../../../hooks/useTypedSelector";


function BoxOpenWindow(openBox: any) {

    const {setOpenBox, setTutorial} = useActions();
    const {tutorial} = useTypedSelector(state => state.auth);

    let word = 'First';

    switch (openBox.openBox.number_in_library) {
        case 2:
            word = 'Second'
            break;
        case 3:
            word = 'Third';
            break;
        case 4:
            word = 'Fourth';
            break;
        case 5:
            word = 'Fifth';
            break;
        default:
            word = 'First';
    }

    let text = word + ' in Library';

    if(openBox.openBox.cashback !== false){
        text = 'Full set. ' + openBox.openBox.cashback + ' Gems returned.'
    }

    return (
        <div>
            <div className="pyro">
                <div className="before"></div>
                <div className="after"></div>
            </div>
            <div className={'shopOpenBoxContainer'}>
                <div className={'shopLibraryNum'}>{text} ({openBox.openBox.number_in_library}/5)</div>
                <Card card={openBox.openBox.card} battle={false} isBigSize={true}/>

                <div className='shopButtonDone'>
                    <Button size="large" type="primary" onClick={() => {
                        setOpenBox(false);

                        // Tutorial
                        if(tutorial.step === 'ClickShopDone105'){
                            tutorial.step = 'ClickDeck110';
                            setTutorial(tutorial);
                        }
                        // End
                    }}>
                        Done
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default BoxOpenWindow;