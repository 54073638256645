function ManaCost({costs, level, isVeryBigSize}) {

    let manaCosts = costs;

    if(typeof costs === 'string'){
        manaCosts = JSON.parse(manaCosts);
    }

    let manaCostSizeClass = 'manaCostNormal';
    if(isVeryBigSize !== false){
        manaCostSizeClass = 'manaCostVeryBig';
    }

    return (
        <div className='manaCost'>
            {Object.keys(manaCosts).map((item, index) => {
                return <div key={index} className={manaCostSizeClass + ' manaCostItem ' + item + 'Card ' + item + 'Card-' + level}>
                    <div>{manaCosts[item]}</div>
                </div>
            })}

        </div>
    );
}

export default ManaCost;