import React from 'react';

import './PlayerName.css';
import {useTypedSelector} from "../../../../hooks/useTypedSelector";


function PlayerName() {

    const {user} = useTypedSelector(state => state.auth);
    let login = user.login;

    login = login.replace(/\@.*/,'');

    return (
        <div className='playerName'>{login}</div>

    );
}

export default PlayerName;