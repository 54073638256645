import './Shop.css';
import {NutakuPaymentService} from "../../../services/NutakuPaymentService";

function NutakuBox() {

    return (
        <div onClick={()=>{

            NutakuPaymentService.start(1);
        }}>
            Buy for NUTAKU gold 100
        </div>
    );
};



export default NutakuBox;